/*
 * @Description:
 * @Author: zby
 * @Date: 2021-11-30 14:13:03
 * @LastEditors: zby
 * @Reference:
 */
import { BaseService } from '@/api/request';
// NaN undefined null过滤处理
// 注意，尽量不要过滤空字符串
const filterNull = json => {
  let newJson = {};
  let reg = RegExp(/NaN|undefined|null/);
  for (let li in json) {
    if (!reg.test(json[li])) {
      newJson[li] = json[li];
    }
  }
  return newJson;
};
const api = {};

/**
 * @description: 获取项目列表
 * @param  {*page 页码}
 * @param  {*pagesize 页面大小}
 */
api.getProject = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/getProject', params);
};

/**
 * @description: 获取工作记录
 * @param  {*project 项目id}
 * @param  {*startDate 开始日期，如果不是一个有效的周一的日期请求会被直接拒绝}
 */
api.getWorkLog = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/worklog/getWorkLog', params);
};
api.getUserAffairLog = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/affair/getUserAffairLog', params);
};
// 获取节假日/补班日等假期
api.getholidayLog = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/dates', params);
};
/**
 * @description: 创建服务项
 * @param  {*name 新建服务名称}
 */
api.createService = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/service/createService', params);
};
api.getPublicAffairs = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/affair/searchAffairs', params);
};
/**
 * @description: 提交工作记录
 * @param  {*weekId 周id}
 * @param  {*detail.service 服务id}
 * @param  {*detail.task 任务id}
 * @param  {*?detail.workLog.id 工作记录id}
 * @param  {*detail.workLog.weekNumber 1-6指代周一到周六，7指代周日}
 * @param  {*detail.workLog.duration 工作时长}
 */
api.submitWorkLog = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/worklog/submitWorkLog', params);
};
api.sumbitAffairLog = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/affair/sumbitAffairLog', params);
};
/**
 * @description: 获取用户工时详情
 * @param  {*weekStart 开始日期，需要提供一个有效的周一的日期}
 * @param  {*today 开始日期，当日的日期}
 */
api.getWorkTime = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/worklog/getUserDuration', params);
};
api.addSupplementAudit = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/approval_worklogs/single_commit', params);
};
api.withDrawalAudit = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/approval_worklogs/self_rejected', params);
};

// 获取任务列表
api.getTaskList = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/jobLogs', filterNull(params));
};
// 任务填报
api.submitTask = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/jobLogs', params);
};

export default api;
