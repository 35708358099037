import { BaseService } from '@/api/request';
const api = {};

/**
 * @description: 获取个人每周的待审核/已审核事项列表
 */
api.getWeeklyAuditAffairs = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/approval_worklogs/week_list', params);
};
/**
 * @description: 工时审核通过/驳回
 */
api.submitAudit = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/approval_worklogs/approval', params);
};
/**
 * @description: 获取工时审核周列表
 * @param  {*weekStart 当周的周一的日期的字符串}
 */
api.getAuditList = function (params = {}) {
  return BaseService.getRequest('/api/pmf/v1/approval_worklogs', params);
};
export default api;
