import { BaseService } from '@/api/request';
const api = {};

/**
 * @description: 获取个人每周统计时间
 * @param  {*weekStart 当周的周一的日期的字符串}
 */
api.getPersonWeekSummary = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/affair/personWeekSummary', params);
};
api.submitWorkRecord = function (params = {}) {
  return BaseService.postRequest('/api/pmf/v1/approval_worklogs/commit', params);
};
api.getProgressRecord = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/approval_worklogs/approving_list', params);
};
api.checkCanSubmit = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/approval_worklogs/can_commit', params);
};
export default api;

api.restartSubmitAudit = function (params = {}) {
  const { id } = params;
  return BaseService.postRequest(`api/pmf/v1/approval_worklogs/${id}/recommit`, params);
};
