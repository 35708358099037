<template>
  <div class="work-record__page">
    <div class="tree-sub" ref="tree-sub">
      <div class="tree-menu first-menu" :style="treeHeight">
        <TreeMenu
          ref="treeMenu"
          :treeMenuName="$t('AuditManagement.auditing')"
          :searchName="$t('AuditManagement.searchDate')"
          :projectArr="auditingArr"
          :mutipleCheck="chooseTree === 'auditing'"
          :mutipleActive="true"
          :choosedContractItem="choosedContractItem"
          @getChoosedContractItem="getChoosedContractItem"
          :arrSum="true"
          searchType="auditingSearch"
          @searchAuditDate="searchAuditDate"
          remoteSearch
          :initialState="initialState"
        />
      </div>
      <div class="tree-menu" :style="treeHeight">
        <TreeMenu
          ref="treeMenu"
          :treeMenuName="$t('AuditManagement.audited')"
          :searchName="$t('AuditManagement.searchDate')"
          :projectArr="auditedArr"
          :mutipleCheck="chooseTree === 'audited'"
          :mutipleActive="true"
          @searchAuditDate="searchAuditDate"
          :choosedContractItem="choosedContractItem"
          @getChoosedContractItem="getChoosedContractItem"
          :arrSum="true"
          remoteSearch
          :initialState="initialState"
          searchType="auditedSearch"
        />
      </div>
    </div>
    <div id="tableRange" :style="{ width: tableWidth + 'px !important' }">
      <div class="record-box">
        <div class="header" ref="header">
          <p class="headerTitle">{{ chooseTree === 'audited' ? $t('AuditManagement.haveApproved') : $t('AuditManagement.pendingApprove') }}</p>
          <div class="box">
            <div class="circle"></div>
            <p class="currentRord">{{ this.choosedContractItem.name }}</p>
          </div>
          <el-popover placement="left-start" width="280" trigger="click" class="showTips">
            <ul class="tips">
              <li>
                <div class="overHours colorPick"></div>
                <p>{{ $t('AuditManagement.WorkingHoursRange') }}</p>
              </li>
              <li class="middleLi">
                <div class="overLeaveHours colorPick"></div>
                <p>{{ $t('AuditManagement.overLeaveHours') }}</p>
              </li>
              <li>
                <div class="lessLeaveHours colorPick"></div>
                <p>{{ $t('AuditManagement.limitLeaveHours') }}</p>
              </li>
            </ul>
            <el-button slot="reference" :class="['unactived', { actived: hoverType }]" @mouseenter.native="hoverCheck(1)" @mouseleave="hoverCheck(0)">
            </el-button>
          </el-popover>
        </div>
      </div>

      <AuditTable
        :tableHeight="tableHeight"
        ref="AuditTable"
        :tableData="tableData"
        :weeklyDay="weeklyDay"
        :holidayWeek="holidayWeek"
        @switchTabHours="switchTabHours"
        :weeklyDuration="weeklyDuration"
        :tableLoading="tableLoading"
        @selectRow="selectRow"
      />
      <div v-if="chooseTree === 'auditing'" class="button-group" ref="button-group">
        <el-button style="height: 32px" type="default" size="mini" class="rejectButton" @click="rejectAudit">驳回</el-button>
        <el-button style="height: 32px" type="primary" size="mini" class="passButton" @click="passAudit">通 过</el-button>
        <el-button style="height: 32px" type="primary" size="mini" class="allPassButton" @click="allPassAudit">
          <GLIcon icon="icon-quanbutongguo" class="quanbutongguo" />
          全部通过
        </el-button>
      </div>
    </div>
    <SimpleDialog
      class="defineDialog"
      title="提示"
      width="420px"
      :visible.sync="dialogVisible"
      :confirmBtnText="dialogType === 'reject' ? '驳回' : '通过'"
      :confirmBtnLoading="dialogLoading"
      showConfirm
      showCancel
      :show-close="false"
      @onCancel="dialogClose"
      @onSubmit="dialogSubmit"
      :before-close="dialogClose"
    >
      <div v-if="dialogType === 'reject'" class="rejectArea">
        <el-form :model="rejectForm" ref="rejectForm" :rules="rejectRules" label-width="150px" label-position="top">
          <el-form-item prop="rejectReason" label="请填写驳回原因">
            <el-input type="textarea" v-model="rejectForm.rejectReason" :autosize="{ minRows: 4, maxRows: 4 }" class="texts"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <p v-if="dialogType === 'pass'" class="tip" v-html="passAuditTips"></p>
      <p v-if="dialogType === 'allPass'" class="tip" v-html="allPassAuditTips"></p>
    </SimpleDialog>
  </div>
</template>

<script>
import TreeMenu from '@/components/TreeMenu/TreeMenu.vue';
import AuditTable from './components/AuditTable.vue';
import personalWorkingApi from '@/api/modules/personalWorking.api.js';
import workRecordApi from '@/api/modules/workRecord.api.js';
import auditApi from '@/api/modules/audit.api.js';

import { debounce } from '@/utils/debounce';
import { getDayAll, dayFormat } from '@/utils/time-tools';

import { mixinsWorkRecord } from '@/mixins/treeMenu';
export default {
  name: 'WorkRecord',
  components: { TreeMenu, AuditTable },
  mixins: [mixinsWorkRecord],
  data() {
    return {
      tableWidth: 0,
      tableHeight: 0,
      dialogVisible: false,
      dialogLoading: false,
      auditingArr: [], //项目列表
      auditedArr: [],
      tableData: [], //准备渲染表格的数据
      weeklyDay: [], //表格数据的日期
      holidayWeek: [],
      weeklyDuration: [],
      weekRange: {}, //选择周的范围
      choosedContractItem: {}, //当前选择的项目
      tableLoading: false,
      currentDate: '',

      focusDayCheck: false,
      lastChoosedContractItem: null,
      dayDuration: 0,
      todayHours: 0,
      weekHours: 0,
      chooseTree: 'auditing',
      selectRowData: [],
      dialogType: '',
      auditStatus: '',
      rejectForm: {
        rejectReason: '',
      },
      rejectRules: { rejectReason: [{ type: 'string', required: true, message: '驳回原因必填', trigger: 'change' }] },
      passAuditTips: '',
      allPassAuditTips: '',
      initialState: false,
      hoverType: 0,
    };
  },
  created() {
    this.createLoad();
  },
  methods: {
    searchAuditDate(dateVal, searchTreeType) {
      if (searchTreeType === 'auditedSearch') {
        this.getAuditedAffairs(dateVal);
      } else {
        this.getAuditingAffairs(dateVal);
      }
    },
    calTableRangeWidth() {
      this.tableWidth = window.innerWidth - this.$refs['tree-sub'].offsetWidth - 16 - 28 - (this.$store.getters.sidebar.opened ? 208 : 55);
    },
    calTableDom() {
      const resizeDom = debounce(() => {
        // 上导航(包含内外边距) 路由菜单滑轨(包含内外边距) ref->top-box,header bottom-box的内边距和外边距
        this.otherDomHeight = (this.$refs.header?.offsetHeight ?? 0) + 32 + 50 + 24;
        this.tableHeight =
          window.innerHeight - (this.otherDomHeight + (this.chooseTree === 'auditing' ? (this.$refs['button-group']?.offsetHeight ?? 0) + 20 : 0));
      }, 0);
      resizeDom();
    },
    selectRow(arr) {
      this.selectRowData = [...arr];
    },
    hoverCheck(item) {
      this.hoverType = item;
    },
    disposalData(rowData) {
      // 以单条数据为维度进行整理，可处理项目/员工对应项目/员工对应服务项数据等单条数据
      if (rowData.projectId) {
        return {
          sortId: rowData.sortId ?? '',
          name: rowData.projectName,
          projectId: rowData.projectId ?? '',
          projectName: rowData.projectName ?? '',
          totalDuration: rowData.totalDuration ?? '',
          createdAt: rowData.createdAt ?? '',
          handledAt: rowData.handledAt ?? '',
          list: rowData.list.length ? rowData.list : null,
          // servings: rowData.servings?.length ? rowData.servings : null,
        };
      }
      if (rowData.userId) {
        return {
          id: rowData.id ?? '',
          sortId: rowData.userId + rowData.rowKey,
          parentId: rowData.parentId,
          name: rowData.userName,
          userId: rowData.userId ?? '',
          userName: rowData.userName ?? '',
          totalDuration: rowData.totalDuration ?? '',
          reason: rowData.reason ?? '',
          approverName: rowData.approverName ?? '',
          status: rowData.id === '0' ? 'unsubmit' : rowData.status,
          createdAt: rowData.createdAt ?? '',
          handledAt: rowData.handledAt ?? '',
          servings: rowData.servings.length ? rowData.servings : null,
          durations: rowData.durations?.length ? [...rowData.durations] : [],
        };
      }
      if (rowData.servingId) {
        return {
          sortId: rowData.servingId ? rowData.servingId + rowData.rowKey : '',
          name: rowData.servingName,
          servingId: rowData.servingId ?? '',
          servingName: rowData.servingName ?? '',
          totalDuration: rowData.totalDuration ?? '',
          durations: rowData.durations?.length ? [...rowData.durations] : [],
          // servings: rowData.servings?.length ? rowData.servings : null,
        };
      }
    },
    async createLoad() {
      this.firstLoad = true;
      await this.getAuditingAffairs();
      await this.getAuditedAffairs();
      this.firstLoad = false;
    },
    rejectAudit() {
      if (!this.selectRowData.length) {
        this.$message.error('请至少选择一项数据');
        return;
      }
      this.dialogType = 'reject';
      this.auditStatus = 'rejected';
      this.dialogVisible = true;
    },
    passAudit() {
      if (!this.selectRowData.length) {
        this.$message.error('请至少选择一项数据');
        return;
      }
      let filterRowData = this.tableData.map(i => i.list).flat();
      filterRowData = filterRowData.filter(item => this.selectRowData.includes(item.id));

      const rejectArr = filterRowData.find(val => {
        return val.status === 'rejected';
      });
      if (rejectArr) {
        this.passAuditTips = '选中数据包括“已驳回”记录，是否继续通过？通过后不可撤销。<br/>注：如不通过该记录，请重新勾选需通过记录后点击“通过”';
      } else {
        this.passAuditTips = '是否通过所选行的工时情况？通过后不可撤销';
      }
      this.dialogType = 'pass';
      this.auditStatus = 'approved';
      this.dialogVisible = true;
    },
    allPassAudit() {
      const filterRowData = this.tableData.map(i => i.list).flat();
      const selectIds = filterRowData.filter(val => Number(val.id)).map(ad => ad.id);
      this.selectRowData = [...selectIds];
      if (!this.selectRowData.length) {
        this.$message.error('没有符合条件的待提交数据');
        return;
      }
      const filterallRowData = filterRowData.filter(item => this.selectRowData.includes(item.id));
      const rejectArr = filterallRowData.find(val => {
        return val.status === 'rejected';
      });

      if (rejectArr) {
        this.allPassAuditTips = '选中数据包括“已驳回”记录，是否继续通过？通过后不可撤销。<br/>注：如不通过该记录，请重新勾选需通过记录后点击“通过”';
      } else {
        this.allPassAuditTips = '是否通过所有提交的工时情况？通过后不可撤销';
      }
      this.dialogType = 'allPass';
      this.auditStatus = 'approved';
      this.dialogVisible = true;
    },
    async dialogSubmit() {
      if (this.auditStatus === 'rejected') {
        await this.$refs.rejectForm.validate(async valid => {
          if (!valid) {
            return;
          }
          await this.submitAudit();
        });
      } else {
        await this.submitAudit();
      }
    },
    async submitAudit() {
      this.dialogLoading = true;
      await auditApi.submitAudit({
        ids: this.selectRowData,
        status: this.auditStatus,
        reason: this.rejectForm.rejectReason,
      });

      this.dialogLoading = false;
      this.dialogClose();
      this.$message.success('操作成功');
      this.lastChoosedContractItem = this.choosedContractItem;
      this.createLoad();
      this.initialState = !this.initialState;
    },
    dialogClose() {
      this.auditStatus === 'rejected' ? this.$refs.rejectForm.resetFields() : null;
      this.dialogLoading = false;
      this.dialogVisible = false;
    },
    //获取周的时间
    getWeekRange() {
      const startTime = this.weekRange.startDateTime.replace(/-/g, '/');
      const endTime = this.weekRange.endDateTime.replace(/-/g, '/');
      this.weeklyDay = getDayAll(startTime, endTime).map(i => i.split('-').splice(1).join('-'));
      this.getUserWorkTime();
    },
    //获取待审核列表
    async getAuditingAffairs(dateVal) {
      let beginDate = '';
      let endDate = '';
      console.log(dateVal);

      if (dateVal) {
        beginDate = dateVal[0];
        endDate = dateVal[1];
      }
      let [data] = await auditApi.getWeeklyAuditAffairs({ beginDate, endDate, status: 'committed' });
      if (!data || !data.weeks.length) return (this.tableData = []);
      const filterHourArr = data.weeks.map(val => {
        return {
          name: val.beginDate + '~' + val.endDate,
        };
      });
      this.weekRange.startDateTime = filterHourArr[0].beginDate;
      this.weekRange.endDateTime = filterHourArr[0].endDate;
      this.auditingArr = [...filterHourArr];
      if (this.lastChoosedContractItem) {
        const lastChoosedContractIndex = filterHourArr.findIndex(item => {
          return item.name === this.lastChoosedContractItem.name;
        });
        if (lastChoosedContractIndex !== -1) {
          this.getChoosedContractItem(filterHourArr[lastChoosedContractIndex]);
        } else {
          this.getChoosedContractItem(filterHourArr[0]);
        }
      } else {
        this.getChoosedContractItem(filterHourArr[0]);
      }
    },
    //获取已审核列表
    async getAuditedAffairs(dateVal) {
      let beginDate = '';
      let endDate = '';
      console.log(dateVal);

      if (dateVal) {
        beginDate = dateVal[0];
        endDate = dateVal[1];
      }
      let [AuditedData] = await auditApi.getWeeklyAuditAffairs({ beginDate, endDate, status: 'approved' });
      if (!AuditedData || !AuditedData.weeks.length) return (this.tableData = []);
      const filterHourArr = AuditedData.weeks.map(val => {
        return {
          name: val.beginDate + '~' + val.endDate,
          showRange: 0,
        };
      });
      this.auditedArr = [...filterHourArr];
    },
    // 变更当周个人当日工时
    switchTabHours(vals, dateVal) {
      if (!vals.length) {
        this.todayHours = this.dayDuration;
        this.focusDayCheck = false;
        this.currentDate = '';
        return;
      }
      this.currentDate = dateVal;
      this.focusDayCheck = true;
      let [hours] = vals;
      this.todayHours = hours;
    },

    //获取选择的那个项目
    async getChoosedContractItem(i, listType = false) {
      this.weekRange.startDateTime = i.name.split('~')[0];
      this.weekRange.endDateTime = i.name.split('~')[1];
      this.choosedContractItem = i;
      this.getWeekRange();
      if (listType) {
        this.chooseTree = 'audited';
        this.auditStatus = 'approved';
        await this.getTableList();
        return;
      }
      this.chooseTree = 'auditing';
      this.auditStatus = 'approving';
      await this.getTableList();
    },

    //获取用户工时
    async getUserWorkTime() {
      let [data] = await workRecordApi.getWorkTime({
        weekStart: this.weekRange.startDateTime,
        today: dayFormat(+new Date(), 'YYYY-MM-DD'),
      });
      if (!data) return;
      this.todayHours = data.dayDuration ? data.dayDuration : 0;
      this.dayDuration = data.dayDuration ? data.dayDuration : 0;
      this.weekHours = data.weekDuration ? data.weekDuration : 0;
    },

    async getTableList() {
      this.tableData = [];
      this.tableLoading = true;
      let startDates = this.weekRange.startDateTime;
      let endDate = this.weekRange.endDateTime;

      let [dateData] = await workRecordApi.getholidayLog({
        beginDate: startDates,
        endDate,
      });
      this.holidayWeek = [...dateData.dates];
      let [summaryCurrentDay] = await personalWorkingApi.getPersonWeekSummary({ weekStart: startDates });
      this.weeklyDuration = [...summaryCurrentDay.weekSummary.duration];
      let [data] = await auditApi.getAuditList({
        beginDate: startDates,
        endDate,
        status: this.auditStatus,
      });
      if (!data) {
        this.$message.error('请求出错，请联系相关开发人员');
        this.tableData = [];
        this.tableLoading = false;
        return;
      }
      let list = data.list || [];
      if (!list.length) {
        this.tableData = [];
        this.tableLoading = false;
        return;
      }
      let addSum = 0;
      // 对父项和子项进行剥离，整理为树状结构table，单条数据的格式交给disposalData处理
      list.map((i, idx) => {
        const temRow = i;
        temRow.rowKey = ++addSum;
        temRow.name = temRow.projectName;
        temRow.sortId = temRow.projectId + temRow.rowKey;
        list[idx] = this.disposalData(temRow);
        const projSortId = temRow.sortId;
        if (i.list?.length) {
          i.list.map((val, valIdx) => {
            val.rowKey = ++addSum;
            val.name = val.userName;
            val.parentId = projSortId;
            i.list[valIdx] = JSON.parse(JSON.stringify(this.disposalData(val)));
            val.servings.map((item, serIdx) => {
              item.rowKey = ++addSum;
              item.name = item.servingName;
              i.list[valIdx].servings[serIdx] = this.disposalData(item);
            });
            i.list[valIdx].list = i.list[valIdx].servings ?? [];
          });
        }
      });
      this.tableData = [...list];
      this.tableLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/compsStyle/resource.scss';
@import '@/styles/element-ui.scss';

::v-deep .el-dialog__header {
  height: 40px;
  padding: 10px 18px;
}
::v-deep .el-checkbox__inner {
  width: 18px;
  height: 18px;
}
::v-deep .el-checkbox__inner::after {
  left: 6px;
  top: 3px;
}
::v-deep .el-dialog__footer {
  border-top: 1px solid #f0f0f0;
  .footer {
    margin-top: 8px;
    margin-right: 10px;
    .el-button {
      width: 57px !important;
      height: 28px !important;
    }
  }
}
.tips {
  padding-top: 4px;
  padding-bottom: 4px;
  li {
    display: flex;
    align-items: center;

    p {
      display: inline-block;
      margin: 0;
      margin-right: 16px;
    }
    .colorPick {
      margin-right: 8px;
      display: inline-block;
      width: 24px;
      height: 15px;
    }
    .overHours {
      background: #ffe0dd;
    }
    .overLeaveHours {
      background: #9acbff;
    }
    .lessLeaveHours {
      background: #d4e9ff;
    }
  }
  .middleLi {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.work-record__page {
  display: flex;
  flex: auto;
  .tree-sub {
    margin-left: 24px;
    .tree-menu {
      ::v-deep .tree-menu__box {
        .tit {
          // height: 68px;
          // line-height: 80px;
          padding-top: 22px;
          margin-bottom: 6px;
        }
      }
    }
    .first-menu {
      margin-bottom: 16px;
    }
  }
  #tableRange {
    width: 100%;
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 2px;
    margin: 0 16px;
    margin-right: 24px;
    margin-top: 0;
    overflow: hidden;
    flex: 1;

    .record-box {
      .header {
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        height: 56px;
        .headerTitle {
          margin: 0;
          display: inline-block;
          font-size: 16px;

          font-weight: 600;
          color: #17233d;
        }
        .box {
          display: inline-block;
          position: relative;
          // min-width: 92px;
          min-height: 24px;
          margin-left: 8px;

          background: #f5f7fa;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2d8cf0;
          .circle {
            position: absolute;
            top: calc(50% - 3px);
            left: 12px;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background: #2d8cf0;
          }
          .currentRord {
            color: #515a6e;
            display: inline-block;
            margin: 0;
            padding-left: 24px;
            padding-right: 12px;
            line-height: 24px;
          }
        }
        .showTips {
          margin-left: auto;
          .unactived {
            position: relative;
            cursor: pointer;
            width: 28px;
            height: 28px;
            border-radius: 4px;
            border: 1px solid #e8e8e8;
          }
          .actived {
            border: 1px solid rgba(24, 144, 255, 0.2);
          }
          .unactived::before {
            content: '';
            content: '';
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            width: 12px;
            height: 15px;
            top: 5px;
            background: url('~@/assets/tabIcon/tip.png') no-repeat;
            background-size: cover;
          }
          .unactived:hover::before {
            vertical-align: center;
            background: url('~@/assets/tabIcon/tip_actived.png') no-repeat;
            background-size: cover;
          }
        }
      }
    }
    .button-group {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
      .el-button {
        height: 36px;
        border-radius: 4px;
        padding: 0 15px;
        .quanbutongguo {
          margin-bottom: -1px;
        }
      }
    }
  }
  .defineDialog {
    .rejectArea {
      margin: 0 auto;
      padding: 8px 0 12px 0;
      width: 95%;

      p {
        line-height: 32px;
        margin: 0;
        margin-bottom: 8px;
      }
    }
    .tip {
      line-height: 32px;
      margin: 0 auto;
      padding: 20px 10px;
      width: 100%;
      text-align: left;
    }
    ::v-deep .el-dialog__footer {
      padding-top: 0;
      .el-button {
        width: 57px;
        line-height: 0;
        height: 28px;
      }
    }
  }
}
@media screen and (min-width: 350px) and (max-width: 900px) {
  .tree-sub {
    margin-left: 8px !important;
  }
  ::v-deep .tree-menu__box {
    max-width: 188px !important;
  }
  #tableRange {
    flex: auto !important;
    background-color: #fff;
    padding: 12px 12px !important;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  .tree-sub {
    margin-left: 16px !important;
  }
  ::v-deep .tree-menu__box {
    max-width: 228px !important;
  }
  #tableRange {
    flex: auto !important;
    background-color: #fff;
    padding: 12px 12px !important;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0 !important;
  }
}
</style>
