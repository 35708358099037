<!--
 * @Description:
 * @Author: hgy
 * @Date: 2022-10-25 09:49:15
 * @LastEditors: hgy
 * @Reference:
-->
<template>
  <el-table
    ref="workHourTable"
    :class="{ show_empty: showEmpty }"
    v-loading="tableLoading"
    :data="cloneTableData"
    :max-height="tableHeight"
    highlight-current-row
    @selection-change="handleSelectionChange"
    :expand-row-keys="expands"
    row-key="sortId"
    border
    :row-class-name="servingRowClass"
    :tree-props="{ children: 'list' }"
    empty-text=" "
    :cell-class-name="hiddenSelectCell"
    @select-all="selectAll"
    @select="selectNode"
  >
    <el-table-column class="selectionType" type="selection" :selectable="selectable" width="35"> </el-table-column>
    <el-table-column align="left" :label="$t('AuditManagement.member')" min-width="140" prop="name" :show-overflow-tooltip="true"> </el-table-column>

    <el-table-column :align="'center'" :label="$t('AuditManagement.totalDuration')" width="80">
      <template slot-scope="scope">
        <div :class="{ totalSum: scope.row.totalDuration > 44 && !scope.row.projectId }">
          {{ scope.row.totalDuration }}
        </div>
      </template>
    </el-table-column>

    <el-table-column align="center" v-for="(item, idx) in weeklySums" :key="item.simpDes" :min-width="$i18n.locale == 'en' ? '80' : '70'">
      <template #header>
        <div
          :tabindex="idx"
          :class="['header-text', headerCellHoliday(item), headerCellStyle(item)]"
          @mouseover="showHeaderTips(item, idx)"
          @mouseout="removeFocus()"
          @focus="switchTabTitle(item, weeklyDay[idx])"
          @blur="switchTabTitle()"
        >
          <div :class="['weeklyToolTip', headerCellStyle(item)]" :style="{ display: idx === hoverIndex ? 'block' : 'none' }">
            <span>当日总工时</span>
            <span>{{ weeklyDuration[item.weekIndex] }}小时</span>
          </div>
          <div :class="[{ currentDColor: weeklyDay[idx] === currentDayDate }, 'holidayColor']">
            <span> {{ weeklyDay[idx] === currentDayDate ? '今天' : $t('workRecord.' + item.fullDes) }}</span>
            <div class="dayStyle">{{ weeklyDay[idx] }}</div>
          </div>
        </div>
      </template>
      <template slot-scope="scope">
        <template>
          <el-popover
            v-if="scope.row.servingId && getRemark(scope.row, idx)"
            placement="top"
            width="240"
            trigger="hover"
            :content="getRemark(scope.row, idx)"
            popper-class="poppers"
          >
            <span slot="reference" class="inputShadow popperRemarks">{{ getDuration(scope.row, idx) }}</span>
          </el-popover>
          <div v-else-if="!scope.row.servingId" :class="['inputShadow', checkHour(scope.row, idx)]">
            {{ getDuration(scope.row, idx) }}
          </div>
          <div v-else-if="scope.row.servingId" :class="['inputShadow']">
            {{ getDuration(scope.row, idx) }}
          </div>
        </template>
      </template>
    </el-table-column>
    <el-table-column :align="'center'" min-width="60">
      <template #header>
        <p class="details" @click="expandControl">
          <transition name="slide-fade"> <i v-show="expandsStatus" class="el-icon-arrow-up expand-up"></i> </transition>
          <transition name="slide-faded"> <i v-show="!expandsStatus" class="el-icon-arrow-down expand-up"></i> </transition>
          {{ $t('AuditManagement.detail') }}
        </p>
      </template>
      <template slot-scope="scope">
        <p
          v-if="!scope.row.servingId"
          :class="['checkDetail', { isExpand: !scope.row.list.length }]"
          @click="expandDetail(scope.row, scope.column, scope.index)"
        >
          {{ $t('AuditManagement.ViewDetails') }}
        </p>
      </template>
    </el-table-column>
    <el-table-column :align="'center'" :label="$t('AuditManagement.status')" :min-width="$i18n.locale == 'en' ? '80' : '60'">
      <template slot-scope="scope">
        <el-popover
          v-if="scope.row.reason && (scope.row.status === 'rejected' || scope.row.status === 'recommitted')"
          placement="top"
          width="240"
          trigger="hover"
          :content="scope.row.approverName + ':' + scope.row.reason"
          popper-class="poppers"
        >
          <div slot="reference">
            <div class="statusDes" :style="'color:red'">
              <div :class="['circle', showStatus('icon', scope.row.status)]"></div>
              {{ $t(showStatus('name', scope.row.status)) }}
            </div>
          </div>
        </el-popover>
        <div v-else-if="!scope.row.reason && (scope.row.status === 'rejected' || scope.row.status === 'recommitted')">
          <div class="statusDes" :style="'color:red'">
            <div :class="['circle', showStatus('icon', scope.row.status)]"></div>
            {{ $t(showStatus('name', scope.row.status)) }}
          </div>
        </div>
        <div v-else-if="scope.row.status !== 'rejected' && scope.row.status !== 'recommitted'">
          <div class="statusDes">
            <div :class="['circle', showStatus('icon', scope.row.status)]"></div>
            {{ $t(showStatus('name', scope.row.status)) }}
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column :align="'center'" prop="createdAt" :label="$t('AuditManagement.submitDate')" :show-overflow-tooltip="true" min-width="100">
    </el-table-column>
  </el-table>
</template>

<script>
import { dayFormat } from '@/utils/time-tools';
import { debounce } from '@/utils/debounce';

export default {
  name: 'WorkRecordTable',
  props: {
    tableLoading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    weeklyDuration: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chooseTree: {
      type: String,
      default: '',
    },
    tableHeight: {
      type: Number,
      default: 0,
    },
    holidayWeek: {
      type: Array,
      default: () => {
        return [];
      },
    },
    weeklyDay: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      expandArr: [],
      currentDayDate: '',
      mouseKeep: false,
      hoverIndex: -1,
      weeklyHeader: [],
      indexId: undefined,
      currentRow: {},
      inputShow: false,
      iconColorSwitch: false,
      focusId: undefined,
      resizeFlag: null,
      cloneTableData: [],
      serviceId: '',
      taskId: '',
      weekText: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      changeItems: [],
      showEmpty: false,
      rowId: undefined,
      focusRowId: undefined,
      weeklySums: [
        { fullDes: 'monday', simpDes: 'mon', weekIndex: 0 },
        { fullDes: 'tuesday', simpDes: 'tue', weekIndex: 1 },
        { fullDes: 'wednesday', simpDes: 'wed', weekIndex: 2 },
        { fullDes: 'thursday', simpDes: 'thu', weekIndex: 3 },
        { fullDes: 'friday', simpDes: 'fri', weekIndex: 4 },
        { fullDes: 'saturday', simpDes: 'sat', weekIndex: 5 },
        { fullDes: 'sunday', simpDes: 'sun', weekIndex: 6 },
      ],
      selectedNodeData: [],
      selectedUserData: [],
      selectArr: [],
      expands: [],
      filterFinish: false,
      expandsStatus: false,
    };
  },

  watch: {
    weeklyDay: {
      deep: true,
      immediate: false,
      handler() {
        this.currentDayDate = dayFormat(+new Date(), 'MM-DD');
      },
    },
    tableData: {
      deep: true,
      immediate: false,
      handler(val) {
        this.expands = [];
        this.cloneTableData = JSON.parse(JSON.stringify(val));
        this.cloneTableData.forEach(item => {
          this.expands.push(item.sortId);
        });
        !val.length ? (this.showEmpty = true) : (this.showEmpty = false);
        this.changeItems = [];
        // 表格数据变化时，初始化作为当前行+ICON并行显示的指标参数focusId
        this.focusId = undefined;
        this.serviceId = undefined;
        this.taskId = undefined;
      },
    },
  },
  methods: {
    expandControl() {
      this.expands = [];
      if (this.expandsStatus) {
        this.expandRowAll(this.cloneTableData, true);
        this.expandsStatus = false;
      } else {
        this.expandRowAll(this.cloneTableData, false);
        this.expandsStatus = true;
      }
    },
    expandRowAll(arr, isExpand) {
      arr.map(i => {
        this.$refs.workHourTable.toggleRowExpansion(i, isExpand);
        if (i.list && !isExpand) {
          this.expandRowAll(i.list, isExpand);
        }
      });
    },
    selectable(row) {
      if ((row.userId && !Number(row.id)) || row.servingId) {
        return false;
      }
      return true;
    },
    hiddenSelectCell({ row, column } = {}) {
      if (column.label === '成员' && row.projectId) {
        return 'member-table-column';
      }
      if (row.servingId) {
        return 'table-column-hidden';
      }
      if (column.label === '周合计') {
        return 'table-column-totalDuration';
      }
    },
    getDuration(row, idx) {
      if (row.projectId) {
        return;
      }
      if (row.userId) {
        return row.durations[idx]?.duration ? row.durations[idx].duration.toString() : '';
      }
      if (row.servingId) {
        return row.durations[idx]?.duration ? row.durations[idx].duration.toString() : '';
      }
    },
    getRemark(row, idx) {
      return row.durations[idx].remark ? row.durations[idx].remark : '';
    },
    checkHour(row, idx) {
      if (!row.durations) return;
      const leaveHours = row?.durations[idx]?.leaveDuration ?? null;
      if (!leaveHours) {
        const workHours = row?.durations[idx]?.duration ?? null;
        return workHours > 8 ? 'overHour' : null;
      }
      if (leaveHours > 4) {
        return 'leaveHour';
      } else if (leaveHours > 0 && leaveHours <= 4) {
        return ' leaveHalfHour';
      }
    },
    servingRowClass({ row }) {
      if (row.projectId) {
        return 'projectRow';
      }
      if (row.servingId) {
        return 'servingRow';
      }
    },

    selectAll(selection) {
      // 对项目选项进行扫描
      let isAllSelect = false;
      let isAllCancel = false;
      const fullSelectChecked = selection.find(val => val.projectId);
      if (!fullSelectChecked) {
        isAllCancel = true;
        isAllSelect = false;
      } else {
        isAllSelect = true;
        isAllCancel = false;
      }

      // 对子树进行深层次的递归
      if (isAllSelect) {
        selection.map(el => {
          this.selectedNodeData.includes(el.sortId) ? null : this.selectedNodeData.push(el.sortId);
          this.toggleSelection(el, true);
          if (el.list?.length) {
            el.list.forEach(item => {
              this.selectedNodeData.includes(item.sortId) ? null : this.selectedNodeData.push(item.sortId);
            });
            this.setSelectData(el.list, true);
          }
        });
      }
      // 制作中
      if (isAllCancel) {
        this.selectedNodeData = [];
        selection.map(el => {
          this.toggleSelection(el, false);
          if (el.list?.length) {
            this.setSelectData(el.list, false);
          }
        });
      }
    },
    selectNode(selection, row) {
      // 区分两种情况，一种是存在子节点(项目级别)，一种是不存在子节点(用户级别)
      // 最底级的成员项遍历
      if (row.userId) {
        const parentRowData = this.tableData.find(item => item.sortId === row.parentId);
        const parentListData = parentRowData.list.filter(val => Number(val.id));

        if (this.selectedNodeData.includes(row.sortId)) {
          this.selectedNodeData.splice(this.selectedNodeData.indexOf(row.sortId), 1);
          const checkAllEmpty = parentListData.every(i => !this.selectedNodeData.includes(i.sortId));
          if (checkAllEmpty) {
            this.selectedNodeData.splice(this.selectedNodeData.indexOf(parentRowData.sortId), 1);
            this.toggleSelection(
              this.cloneTableData.find(item => item.sortId === parentRowData.sortId),
              false
            );
          }
        } else {
          this.selectedNodeData.push(row.sortId);
          if (!this.selectedNodeData.includes(parentRowData.sortId)) {
            this.selectedNodeData.push(parentRowData.sortId);
            this.toggleSelection(
              this.cloneTableData.find(item => item.sortId === parentRowData.sortId),
              true
            );
          }
        }
      }
      // 项目级别的父项遍历
      else {
        const filterList = row.list.filter(val => Number(val.id));
        // 已选框数组包括了该父项sortId
        if (this.selectedNodeData.includes(row.sortId)) {
          const hasSelectArr = filterList.every(item => this.selectedNodeData.includes(item.sortId));
          // 全选状态，应当重置为全不选
          if (hasSelectArr) {
            this.selectedNodeData.splice(this.selectedNodeData.indexOf(row.sortId), 1);
            filterList.forEach(val => {
              this.toggleSelection(val, false);
              this.selectedNodeData.splice(this.selectedNodeData.indexOf(val.sortId), 1);
            });
          } else {
            filterList.map(val => {
              if (val.servingId) {
                return;
              }
              if (!this.selectedNodeData.includes(val.sortId)) {
                this.toggleSelection(row, true);
                this.toggleSelection(val, true);
                this.selectedNodeData.push(val.sortId);
              }
            });
          }
        } else {
          // 项目层级
          this.selectedNodeData.push(row.sortId);
          if (filterList?.length) {
            filterList.forEach(i => {
              this.toggleSelection(i, true);
              this.selectedNodeData.push(i.sortId);
            });
          }
        }
      }
    },
    // 纯粹勾选可用数据，不要做其他过滤处理
    setSelectData(rowData, type) {
      rowData.map(i => {
        if (i.servingId) {
          return;
        }
        if (i.userId && !Number(i.id)) {
          this.toggleSelection(i, false);
        } else {
          this.toggleSelection(i, type);
        }
      });
    },
    expandDetail(row) {
      if (this.expandArr.includes(row.sortId)) {
        this.$refs.workHourTable.toggleRowExpansion(row, false);
        this.expandArr.splice(this.expandArr.indexOf(row.sortId), 1);
        return;
      }
      this.expandArr.push(row.sortId);
      this.$refs.workHourTable.toggleRowExpansion(row, true);
    },
    toggleSelection(row, select) {
      if (row) {
        this.$nextTick(() => {
          this.$refs.workHourTable.toggleRowSelection(row, select);
        });
      }
    },
    showStatus(name, status) {
      if (name === 'icon') {
        switch (status) {
          case 'unsubmit':
            return 'unsubmit';
          case 'auto_committed':
            return 'auto_committed';
          case 'approving':
            return 'approving';
          case 'approved':
            return 'approved';
          case 'auto_approved':
            return 'auto_approved';
          case 'rejected':
            return 'rejected';
          case 'recommitted':
            return 'rejected';
          case 'finished':
            return 'finished';
        }
      } else if (name === 'name') {
        switch (status) {
          case 'unsubmit':
            return 'AuditManagement.uncommitted';
          case 'auto_committed':
            return 'AuditManagement.Autocommit';
          case 'approving':
            return 'AuditManagement.Unapproved';
          case 'approved':
            return 'AuditManagement.Passed';
          case 'auto_approved':
            return 'AuditManagement.AutomaticApproval';
          case 'rejected':
            return 'AuditManagement.Rejected';
          case 'recommitted':
            return 'AuditManagement.Resubmit';
          case 'finished':
            return 'AuditManagement.Completed';
        }
      }
    },
    async handleSelectionChange(val) {
      this.selectedUserData = [];
      await debounce(this.checkSelect(val), 3000);

      if (this.filterFinish) {
        return;
      }
      this.filterFinish = true;
      this.selectedUserData = this.selectArr.map(val => {
        return val.userId && Number(val.id) ? val.id : null;
      });
      this.selectedUserData = this.selectedUserData.filter(i => i);
      await this.$emit('selectRow', this.selectedUserData);
      setTimeout(() => {
        this.filterFinish = false;
      }, 200);
    },
    checkSelect(val) {
      this.selectArr = [...val];
    },
    removeFocus() {
      this.hoverIndex = -1;
      this.mouseKeep = false;
    },
    showHeaderTips(item, idx) {
      if (this.mouseKeep) {
        return;
      }
      this.mouseKeep = true;
      this.hoverIndex = idx;
    },
    headerCellHoliday(item) {
      if (!this.holidayWeek.length || !this.weeklyDay.length) {
        return;
      }

      const weeklyIdx = item.weekIndex;
      let wekData = this.holidayWeek[weeklyIdx];

      // 普通工作日适用通用规则
      if (wekData.type === 'holiday') {
        return 'holidayHeader';
      }
      if (wekData.isWorkday && wekData.type === 'weekend') {
        return 'makeUpWorkDay';
      }
    },
    switchTabTitle(item, dateVal) {
      if (!this.holidayWeek.length || !this.weeklyDay.length) {
        return;
      }

      if (item) {
        const weeklyIdx = item.weekIndex;
        let weeklyHours = this.weeklyDuration[weeklyIdx];
        this.$emit('switchTabHours', [weeklyHours], dateVal);
      } else {
        this.$emit('switchTabHours', []);
      }
    },
    /* 该api会在每次调用的时候挨次对表头元素进行遍历，故可以利用这个特性和调休日数组以及工时周数组进行同步比对，return对应的样式 */
    headerCellStyle(item) {
      if (!this.holidayWeek.length || !this.weeklyDay.length) {
        return;
      }
      const weeklyIdx = item.weekIndex;
      let wekData = this.holidayWeek[weeklyIdx];
      // 普通工作日适用通用规则
      if (wekData.isWorkday && wekData.type !== 'weekend' && wekData.type !== 'holiday') {
        return this.hoursComputed(this.weeklyDuration[weeklyIdx], 1);
      }
      if (wekData.type === 'weekend' || wekData.type === 'holiday') {
        return this.hoursComputed(this.weeklyDuration[weeklyIdx], 0);
      }
    },
    /*     headerCellClass(item) {

    }, */
    // 针对工作日的样式
    hoursComputed(vals, filterBool) {
      if (filterBool && vals >= 0 && vals < 8) {
        return 'unquilfiedHours';
      } else if (!filterBool && vals > 0 && vals < 8) {
        return 'unquilfiedHours';
      } else if (!filterBool && vals <= 0) {
        return 'hiddenHours';
      }
      if (vals === 8) {
        return 'quilfiedHours';
      }
      if (vals > 8) {
        return 'overHours';
      }
    },
    popShow() {
      this.iconColorSwitch = true;
    },
    popClose() {
      this.iconColorSwitch = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';

::v-deep .el-checkbox__inner {
  display: inline-block;
  width: 20px;
  height: 20px;
}
::v-deep .el-checkbox__inner::after {
  left: 7px;
  top: 3.5px;
}
::v-deep td.el-table__cell {
  .cell {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    height: 40px;
    line-height: 40px;
    .el-table__placeholder {
      width: 0;
    }
  }
}
::v-deep .el-popover--plain {
  padding: 18px 20px;
}

.poppers {
  padding-top: 0;
  .title {
    margin: 4px 0;
    margin-top: 0;
    font-size: 14px;
    margin-left: 2px;
  }
  .el-textarea {
    font-size: 12px;
    width: 245px;
  }
}
/* .el-table--border {
  // border: 1px solid #e8e8e8;
  border-radius: 2px;
  .el-table__cell:first-child {
    .cell {
      padding-left: 0;
    }
  }
} */
::v-deep.el-table {
  color: #515a6e;
  .details {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .slide-fade-enter-active {
      transition: all 0.1s ease;
    }
    .slide-fade-leave-active {
      transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
      transform: translateY(15px);
      opacity: 0;
    }
    .slide-faded-enter-active {
      transition: all 0.1s ease;
    }
    .slide-faded-leave-active {
      transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-faded-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
      transform: translateY(15px);
      opacity: 0;
    }
    .expand-up {
      line-height: 8px;
      margin-right: 6px;
      height: 8px;
      width: 8px;
      display: inline-block;
    }
  }
  .servingRow {
    color: #808695 !important;
    background: #fbfcfb !important;
  }
  .projectRow {
    background: #f2f9fe;
    font-weight: 700;
    height: 48px;
  }
  .table-column-hidden {
    .el-checkbox__input {
      display: none;
    }
  }

  //table_body
  .el-table__body td.el-table__cell {
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
    .el-table__expand-icon {
      display: none;
    }
    .scope-input {
      margin: 0 auto;
      width: 66px;
      position: relative;

      .el-input__inner {
        color: #515a6e;
        border: none;
        text-align: center;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }

      .popoverStyle {
        position: absolute;
        top: 22%;
        right: 0%;
        .bianjibeizhu {
          font-size: 1.2em !important;
        }
      }
    }

    .checkDetail {
      font-weight: normal;
      color: #2d8cf0;
      margin: 0;
    }
    .isExpand {
      color: rgba(45, 140, 240, 0.3);
    }
    .popperRemarks {
      display: inline-block;
      width: 100%;
      height: 29px;
      background: rgba(24, 144, 255, 0.05);
      border-radius: 2px;
      font-size: 14px;
      text-align: center;
      color: #2d8cf0;
      line-height: 29px;
    }
    .inputShadow {
      margin: 0 auto;
      width: 66px;
      height: 29px;
      text-align: center;
    }
    .overHour {
      width: 100%;
      height: 100%;
      background: #ffe0dd;
    }
    .leaveHalfHour {
      width: 100%;
      height: 100%;
      background: #d4e9ff;
    }
    .leaveHour {
      width: 100%;
      height: 100%;
      background: #9acbff;
    }
    .statusDes {
      margin: 0;
      .circle {
        display: inline-block;
        margin-left: 3px;
        margin-bottom: 1px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
      }
      .unsubmit,
      .auto_committed {
        background: #ff9900;
      }

      .approving {
        background: #2d8cf0;
      }
      .approved {
        background: #58ba73;
      }
      .finished {
        background: #58ba73;
      }
      .auto_approved {
        background: #ff9900;
      }
      .rejected {
        background: #da4f2a;
      }
    }

    /*     .inputShadow:hover {
      border: none;
      text-align: center;

      background-color: #fff;
    } */
  }
  .member-table-column {
    padding-left: 5px;
  }
  .table-column-totalDuration {
    font-size: 16px;
  }
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 28px;
  line-height: 28px;
}

@include tableClass;
// 自定义表头样式

.header-text {
  position: relative;
  padding-top: 3px;
  margin: 0 auto;
  // margin-right: 0px;
  text-align: center;
  width: 100%;
  height: 50px;
  border-radius: 4px;

  .currentDColor {
    color: rgba(45, 140, 240, 1);
  }
  .cirStyle {
    position: absolute;
    top: 3px;
    right: 0;
    width: 6px;
    height: 6px;
    background: #f8f8f9;
    border-radius: 50%;
  }
  .dayStyle {
    font-size: 12px;
    font-weight: normal;
  }
  .weeklyToolTip {
    display: none;
    text-align: center;
    z-index: 999999;
    position: absolute;
    left: 39px;
    top: 20px;
    min-width: 4.5vw;
    min-height: 38px;
    line-height: 18px;
    border-radius: 4px;
    span {
      display: inline-block;
      margin: 0;
      font-weight: normal;
      font-size: 12px;
      color: #fff;
    }
  }
}
.header-text:hover {
  cursor: pointer;
  background: rgba(232, 233, 236, 0.5);
}
// 假期
.holidayHeader {
  width: 75%;
  line-height: 22px;
  padding-top: 3px;
  padding-left: 5px;
  background-color: rgba(255, 236, 234, 1);
  color: rgba(219, 79, 42, 1) !important;
  .holidayColor {
    color: rgba(219, 79, 42, 1) !important;
  }
}
.holidayHeader:hover {
  background-color: rgba(255, 236, 234, 0.6) !important;
}
.holidayHeader::after {
  font-weight: normal;
  content: '休';
  font-size: 10px;
  position: absolute;
  top: 1px;
  left: 2px;
}
.makeUpWorkDay {
  line-height: 22px;
  padding-top: 3px;
  padding-left: 5px;
  background-color: rgba(119, 185, 255, 0.2);
  color: #2d8cf0 !important;
  .holidayColor {
    color: #2d8cf0 !important;
  }
}
.makeUpWorkDay:hover {
  background-color: rgba(119, 185, 255, 0.12);
}
.makeUpWorkDay::after {
  font-weight: normal;
  content: '班';
  font-size: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.totalSum {
  background: #ffe0dd;
}
.unquilfiedHours {
  .weeklyToolTip {
    background: rgba(241, 157, 55, 0.7);
  }
}
.quilfiedHours {
  .weeklyToolTip {
    background: rgba(89, 187, 115, 0.7);
  }
}
.hiddenHours {
  .weeklyToolTip {
    display: none !important;
  }
}
.overHours {
  .weeklyToolTip {
    background: rgba(219, 79, 42, 0.7);
  }
  .cirStyle {
    background: rgba(219, 79, 42, 1);
  }
}
::v-deep .el-table__header-wrapper {
  border-radius: 4px 4px 0px 0px;
  .el-table__header {
    .el-table__cell {
      background-color: #f8f8f8f9;
      font-weight: 600;
      font-size: 14px;
      padding: 0;
      height: 56px;
      box-sizing: border-box;
      overflow: visible;
      border-top: none;
      .cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: visible;
        background: #f8f8f9;
        color: #515a6e;

        height: 60px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
